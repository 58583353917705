import { Preset } from 'components/bricks/types/preset';

export const fileTypeExtraFields = {
    image: {
        colorMode: 'Color Mode',
        aspectRatio: 'Aspect Ratio',
        minWidth: 'Minimum Width',
        maxWidth: 'Maximum Width',
        minHeight: 'Minimum Height',
        maxHeight: 'Maximum Height'
    },
    video: {
        maxFileSize: 'Maximum File Size',
        aspectRatio: 'Aspect Ratio',
        codec: 'Codec',
        bitrate: 'Bitrate',
        minWidth: 'Minimum Width',
        maxWidth: 'Maximum Width',
        minHeight: 'Minimum Height',
        maxHeight: 'Maximum Height',
        minDuration: 'Minimum Duration',
        maxDuration: 'Maximum Duration',
        frameRate: 'Frame Rate'
    },
    audio: {
        bitrate: 'Bitrate',
        sampleRate: 'Sample Rate',
        channels: 'Channels'
    },
    document: {
        numberOfPages: 'Number of Pages',
        pageWidth: 'Page Width',
        pageHeight: 'Page Height'
    }
};

export const fileTypeToFormats = {
    image: ['JPEG', 'PNG', 'GIF'],
    video: ['mp4', 'avi', 'mov', 'mkv'],
    audio: ['mp3', 'wav', 'flac'],
    document: ['pdf', 'docx', 'xlsx', 'pptx'],
    zip: ['zip']
};

// Options for the select input
export const fileTypeOptions = [
    { key: 'image', value: 'Image' },
    { key: 'video', value: 'Video' },
    { key: 'audio', value: 'Audio' },
    { key: 'document', value: 'Document' },
    { key: 'zip', value: 'Web Assets' }
];

export const aspectRatioOptions = [
    { key: '1:1', value: '1:1' },
    { key: '16:9', value: '16:9' },
    { key: '9:16', value: '9:16' },
    { key: '4:3', value: '4:3' },
    { key: '3:2', value: '3:2' },
    { key: '1.85:1', value: '1.85:1' },
    { key: '5:4', value: '5:4' },
    { key: 'other', value: 'Other' }
];

export const DEFAULT_CUSTOM_PRESET: Preset = {
    title: '',
    identifier: '',
    description: '',
    searchIndex: '',
    channel: 'all',
    platform: undefined,
    assetType: 'image',
    fileExtension: ['jpg'],
    restrictions: {
        width: 0,
        height: 0,
        minWidth: 0,
        minHeight: 0
    },
    extraFields: [],
    custom: true
};
