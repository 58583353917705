import React, { useMemo } from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import Button from 'components/ui-components-v2/Button';
import { Brick } from 'components/bricks/types/brick.type';
import PublishDetailLogs from 'components/editor-blocks/Publish/components/publish-detail-logs';
import User from 'components/data/User';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import SplitButton from 'components/ui-components-v2/SplitButton';
import MenuItem from 'components/ui-components-v2/MenuItem';
import PublishService from 'components/bricks/services/publish.service';
import ComponentStore from 'components/data/ComponentStore';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';
import StatusIcon from '../../status-icon';
import { BricksPublishTabStatusHelpers } from '../helpers/status.helper';
import '../styles/publishing-progress-status.scss';

interface SplitButtonOption {
    key: string;
    title: string;
    onClick: () => void;
}

interface Props {
    currentBricks: Brick[];
    status: 'success' | 'publishing' | 'error' | 'info';
    publishJobs: BrickPublishJobData[];
    setPublishDialogOpen: (open: boolean) => void;
}

const PublishingProgress = ({ currentBricks, status, publishJobs, setPublishDialogOpen }: Props) => {
    const progress = status === 'publishing' ? BricksPublishTabStatusHelpers.calculateProgress(publishJobs) : undefined;

    const options = useMemo(() => {
        const value: SplitButtonOption[] = [];

        if (status !== 'publishing')
            value.push({
                key: 'retry',
                title: Translation.get(`publishTab.${status}-status.republish`, 'bricks'),
                onClick: () => {
                    if (currentBricks.length === 0) return;
                    setPublishDialogOpen(true);
                }
            });
        else
            value.push({
                key: 'cancel',
                title: Translation.get('output.cancel.copy', 'bricks'),
                onClick: () => {
                    handlePublishJobCancel();
                }
            });

        // Check if there is anything to download
        const hasDownloadItems = publishJobs.some((publishJob) => {
            if (!publishJob.products) return false;
            return Object.values(publishJob.products).some((product) => {
                if (OutputTabHelpers.getProductType(product).action === 'download') return true;
            });
        });

        // If there are items for donload make download button primary one
        if (hasDownloadItems)
            value.unshift({
                key: 'downloadall',
                title: Translation.get('output.download.outputAll', 'bricks'),
                onClick: () => {
                    OutputTabHelpers.handleDownloadAllResults(publishJobs);
                }
            });

        return value;
    }, [publishJobs]);

    const mainButton = options.shift();
    const mainButtonColor = status === 'success' ? 'success' : 'primary';

    const handlePublishJobCancel = () => {
        const jobId = publishJobs[0].jobToken;
        const publishId = publishJobs[0].publishId;

        PublishService.cancelPublishJob(jobId, publishId);
        ComponentStore.removeItem('Bricks', `download.${publishId}`);
    };

    const publishDetailLogsStatus = status === 'publishing' ? 'processing' : 'finished';
    return (
        <div className={`bricks-publish-tab__status`}>
            <div className={`bricks-publish-tab__status__container`}>
                <div className="bricks-publish-tab__status__container__info">
                    <StatusIcon status={status === 'info' ? 'partialerror' : status} />
                    <div className={`bricks-publish-tab__status__container__info__title-container`}>
                        <Typography variant="h5" color="textPrimary">
                            {Translation.get(`publishTab.${status}-status.title`, 'bricks')}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {Translation.get(`publishTab.${status}-status.description`, 'bricks')}
                        </Typography>
                    </div>
                </div>
                <div className={`bricks-publish-tab__status__action`}>
                    {User.hasType('superadmin') && (
                        <PublishDetailLogs
                            jobId={publishJobs[0].jobToken}
                            status={publishDetailLogsStatus}
                            className={`bricks-publish-tab__status__action__logs`}
                            version={3}
                            customParam={{ custom: JSON.stringify({ brickId: currentBricks[0].id }) }}
                        />
                    )}
                    {options.length > 1 && mainButton ? (
                        <SplitButton
                            buttonGroupProps={{ color: mainButtonColor }}
                            mainButton={<Button onClick={mainButton.onClick}>{mainButton.title}</Button>}
                            menuItems={options.map((menuItem) => {
                                return (
                                    <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
                                        {menuItem.title}
                                    </MenuItem>
                                );
                            })}
                        />
                    ) : (
                        <Button onClick={mainButton?.onClick} variant="contained" size="small" color={mainButtonColor}>
                            {mainButton?.title}
                        </Button>
                    )}
                </div>
            </div>
            {!!progress && <LinearProgress className="bricks-publish-tab__status__progress" variant="determinate" value={progress} />}
        </div>
    );
};

export default PublishingProgress;
